import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Ecosystem() {
    return (
        <>
            <div id="wrapper">
                <div id="page" className="pt-40">
                    {/* header */}
                    <Header />
                    <div className="flat-title-page">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="heading text-center">Ecosystem</h1>
                                    <ul className="breadcrumbs flex justify-center">
                                        <li className="icon-keyboard_arrow_right">
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="/comming-soon">Ecosystem</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-section tf-grid-blog pb-80">
                        <div className="themesflat-container">
                            <div className="row">
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smartdefiworld.png" style={{}} alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="https://smartdefiworld.com/" target='_blank'>Smart Defi World</a>
                                            </h5>
                                            <a className="link" href="https://smartdefiworld.com/" target="_blank"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smartbull.png" alt="Smart Bull" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="https://smartbull.io/" target='_blank'>SmartBull</a>
                                            </h5>
                                            <a className="link" href="https://smartbull.io/" target="_blank"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smartdex.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">SmartDex</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smartchain.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">SmartChain</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_web_wallet.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Web wallet</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_mobile_wallet.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Mobile wallet</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_scan.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smartscan</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_bridge.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart bridge</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_launchpad.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Launchpad</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_swap.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smartswap</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_pay.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart pay</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_utilies.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart utilies</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_nft.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart NFT</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_verse.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart verse</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_play.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart play(Web3.0 games)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_show_entertainment.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart show(Entertainment)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_music.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart music</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smartfans_social_media.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smartfans(Social media)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_ecommerce.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">SMart(ecommerce)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_travels.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Travels(travel)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_name_domain.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart nameDomain</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_meet.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smartmeet(Web3.0 Meet)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_storage.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smartstorage(Web3 storage)</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>

                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_games.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Games</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_stakingy.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Stakingy</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_rooms.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Rooms</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_restaurant.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Restaurant</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_bio_farming.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Bio Farming</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_farms.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart farms</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_property.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart Property</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                                <div className="fl-blog fl-item2 col-lg-4 col-md-6">
                                    <article className="tf-card-article">
                                        <div className="card-media">
                                            <a>
                                                <img src="assets/images/ecosystem/smart_talk.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="card-bottom flex items-center justify-between">
                                        </div>
                                        <div className="card-title flex justify-content-between">
                                            <h5><a href="">Smart talk</a>
                                            </h5>
                                            <a className="link" href="/comming-soon"><i className="icon-arrow-up-right2" /></a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                {/* /#page */}
            </div>
        </>
    )
}
