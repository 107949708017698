import React from 'react'

export default function Roadmap() {
    return (
        <>
            <div id='Roadmap' className="tf-section create-sell roadmap-bg">
                <div className="themesflat-container">
                    <div className="row justify-content-center roadmap pt-5">
                        <div className="col-md-12">
                            <div className="heading-section justify-content-center">
                                <h1 className="tf-title pb-30" style={{ perspective: '400px' }}><div style={{ display: 'block', textAlign: 'start', position: 'relative', transformOrigin: '303.172px 22px', transform: 'translate3d(0px, 0px, 0px)', opacity: 1 }}>Futuristic Roadmap</div></h1>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text'>Q1 2024 <br /> Evolution of <br /> Fundamental Attributes</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center type-1">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text'>Q2 2024 <br /> DeFi and Wallet <br /> Introduction, and <br /> Unveiling the Platform</h6>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text px-3'>Q3 2024 <br />Improving Platform  <br /> Features  & User<br /> Engagement</h6>


                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center roadmap-2">
                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text-2 px-3'>Q4 2024 <br />Connecting with Various <br /> Blockchains and <br /> Additional Services</h6>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text px-3'>Q1 2025 <br /> Consistent Evolution <br /> and Expansion</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 m-100">
                            <div className="tf-box-icon relative text-center">
                                <img src="assets/images/1.svg" className='rotated-image-3' alt="" />
                                <img src="assets/images/2.svg" className='rotated-image2' alt="" />
                                <img src="assets/images/3.svg" className='rotated-image' alt="" />
                                <h6 className='roadmap-text px-3'>Q2 2025 <br /> Community-Driven <br /> Development and <br /> Governance</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
