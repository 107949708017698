import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Games() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-section pb-20">
                            <h2 className="tf-title " style={{ perspective: '400px' }}><div style={{ display: 'block', textAlign: 'start', position: 'relative', transformOrigin: '187.461px 22px', transform: 'translate3d(0px, 0px, 0px)', opacity: 1 }}>Futuristic Product</div></h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="featured pt-10 swiper-container carousel3 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                            <Slider {...settings}>
                            <div className="swiper-wrapper" id="swiper-wrapper-d0c29c2de0e33861" aria-live="polite" style={{ transform: 'translate3d(0px, 0px, 0px)', transitionDuration: '0ms' }}>
                                    
                                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 6" style={{ width: '350px', marginRight: '30px' }}>
                                        <div className="tf-card-collection">
                                            <a href="https://smartdefiworld.com" alt="SMART DEFI WORLD" target="_blank">
                                            <img src="assets/images/smart_defi.png" style={{ borderRadius: "10px", marginBottom: "10px" }} alt="" />
                                            <div className="card-bottom">
                                                <div className="author">
                                                    <h5><a>Smart Defi World</a></h5>
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="swiper-wrapper" id="swiper-wrapper-d0c29c2de0e33861" aria-live="polite" style={{ transform: 'translate3d(0px, 0px, 0px)', transitionDuration: '0ms' }}>
                                    
                                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 6" style={{ width: '350px', marginRight: '30px' }}>
                                        <div className="tf-card-collection">
                                            <a href="https://smartbull.io" alt="SMART BULL EXCHANGE" target="_blank">
                                            <img src="assets/images/smart_bull.png" style={{ borderRadius: "10px", marginBottom: "10px" }} alt="" />
                                            <div className="card-bottom">
                                                <div className="author">
                                                    <h5><a>Smart Bull Exchange</a></h5>
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="swiper-wrapper" id="swiper-wrapper-d0c29c2de0e33861" aria-live="polite" style={{ transform: 'translate3d(0px, 0px, 0px)', transitionDuration: '0ms' }}>
                                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 6" style={{ width: '350px', marginRight: '30px' }}>
                                        <div className="tf-card-collection">
                                            <img src="assets/images/comming-1.png" style={{ borderRadius: "10px", marginBottom: "10px" }} alt="" />
                                            <div className="card-bottom">
                                                <div className="author">
                                                    <h5><a>SmartChain</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </Slider>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
                    </div>
                </div>
            </div>
        </>
    )
}