import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import Eccosystem from './Eccosystem'
import Aboutus from './Aboutus'
import Advantages from './Advantages'
import StakingWork from './StakingWork'
import Games from './Games'
import Tokenomics from './Tokenomics'
import Roadmap from './Roadmap'
import FAQ from './FAQ'
import Problem from './Problem'
import CompitiveBenefit from './CompitiveBenefit'
import Transparency from './Transparency'

export default function Index() {
    return (
        <>
            <div id='page' className='' style={{ backgroundImage: `url('assets/images/background.gif')`, opacity: "0.8" }}>
                <Header />
                <Hero />
                <CompitiveBenefit />
                <Aboutus />
                <Problem />
                {/* <Advantages /> */}
                <StakingWork />
                <Games />
                <br />
                <Tokenomics />
                <Transparency/>
                <Roadmap />
                <Eccosystem />
                <FAQ />
                <Footer />
            </div>
        </>
    )
}
