import React from 'react'

export default function Problem() {
    return (
        <>
            <div className="tf-section create-sell">
                <div className="themesflat-container">
                    <div className="row pl-2-5 align-items-center">
                        <div className="col-md-12">
                            <div className="heading-sectio text-center">
                                <h2 className="tf-title pb-30">Problem & Solution</h2>
                                <div className="content">
                                    <p data-wow-delay="0.1s" className="wo">As the digital economy grows, the need for secure, efficient, and transparent financial transactions has become more critical than ever. Traditional financial systems face issues such as high fees, slow processing times, and vulnerability to fraud. While cryptocurrencies have emerged as a solution to some of these challenges by enabling decentralized, peer-to-peer transactions, they fall short in addressing broader demands. Issues like data ownership, privacy, and the integration of decentralized applications remain unresolved, hampering overall efficiency and user experience, and making it difficult for users and businesses to seamlessly interact with both decentralized technologies and traditional financial systems..</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <h1 data-wow-delay="0s" className="wo pb-30">Solution</h1>
                                <p data-wow-delay="0.1s" className="wo">A decentralized ecosystem is required to address these broader needs. Such an ecosystem integrates cryptocurrencies with other decentralized technologies, creating a unified framework that goes beyond financial transactions. This ecosystem would encompass SDC Token, decentralized finance (DeFi), staking, exchanges, gamification, NFTs, and blockchain applications, ensuring that all aspects of the digital economy are covered.</p>
                                <p data-wow-delay="0.1s" className="wo">By incorporating a decentralized ecosystem, we can provide a holistic solution that ensures secure financial transactions, data ownership, privacy, and seamless integration of various decentralized applications. This approach not only facilitates peer-to-peer transactions but also supports broader societal advancements. Ultimately, a comprehensive decentralized ecosystem enables a smooth transition from Web 2 to Web 3, fostering a cohesive and efficient digital experience.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="box-icon-img">
                                <img src="assets/images/solution.gif" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-icon-img">
                                <video muted
                                    autoPlay
                                    loop id="myVideo" className='video'><source src="./assets/images/tech.mp4" type="video/mp4" /></video>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <h1 data-wow-delay="0s" className="wo pb-30">Tech Empowerment Rally</h1>
                                <p data-wow-delay="0.1s" className="wo">SDC, a forward-thinking ecosystem dedicated to pioneering cutting-edge decentralized applications, is thrilled to unveil its groundbreaking staking Platform, designed to operate seamlessly on the BNB Smart Chain.</p>
                                <p data-wow-delay="0.1s" className="wo">The platform empowers users to maximize their SDC holdings by participating in a secure and decentralized staking process, offering attractive rewards.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
