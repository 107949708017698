import React from 'react'

export default function Tokenomics() {
    return (
        <>
            <div id='Tokenomics' className="tf-section create-sell">
                <div className="themesflat-container">
                    <div className="row pl-2-5 align-items-center">
                        <div className="col-md-12">
                            <div className="heading-sectio text-center pt-5">
                                <h2 className="tf-title pb-3">Tokenomics</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="./assets/images/tokenomic.png" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="side-bar">
                                <div className="widget widget-categories">
                                    <h5 className="title-widget">Notice! Be aware of fraud contracts & Please verify the below Token contract address carefully before making any Transaction.</h5>
                                    <ul>
                                        <li>
                                            <div className="cate-item"><a href="#">Name:</a></div>
                                            <div className="number">Smart Digital Connect (SDC)</div>
                                        </li>
                                        <li>
                                            <div className="cate-item"><a href="#">Decimals: </a></div>
                                            <div className="number">8</div>
                                        </li>
                                        <li>
                                            <div className="cate-item"><a href="#">Network: </a></div>
                                            <div className="number">Binance Smart Chain (BEP20)</div>
                                        </li>
                                        <li>
                                            <div className="cate-item"><a href="#">Max Supply:</a></div>
                                            <div className="number">1,00,00,000</div>
                                        </li>
                                        <li>
                                            <div className="cate-item"><a href="#">Contract Address:</a></div>
                                            <div className="number"><a href="https://bscscan.com/token/0xf57fd03eaacd3a9157184a55d8d9117fc84503b7" target='_blank'>0xF57...503B7</a></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
