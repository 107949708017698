import React from 'react'

export default function CompitiveBenefit() {
    return (
        <>
            <div className="tf-section create-sell">
                <div className="themesflat-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="heading-section justify-content-center">
                                <h2 className="tf-title pb-30" style={{ perspective: '400px' }}>
                                    <div style={{ display: 'block', textAlign: 'center', position: 'relative', transformOrigin: '303.172px 22px', transform: 'translate3d(0px, 0px, 0px)', opacity: 1 }}>Futuristic Capabilities</div>
                                </h2>
                            </div>
                        </div>
                        <div data-wow-delay="0s" className="col-lg-2 col-md-6 animated" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step1 relative">
                                <div className="image">
                                    <img src="assets/images/secure_transaction.png" alt="" />
                                </div>
                                <h5 className="heading"><a>Secure Transaction</a></h5>

                            </div>
                        </div>
                        <div data-wow-delay="0.1s" className="col-lg-2 col-md-6 animated" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step2 relative">
                                <div className="image">
                                    <img src="assets/images/powerful_ecosystem.png" alt="" />
                                </div>
                                <h5 className="heading"><a>Powerful Ecosystem</a></h5>

                            </div>
                        </div>
                        <div data-wow-delay="0.2s" className="col-lg-2 col-md-6 animated" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step3 relative">
                                <div className="image">
                                    <img src="assets/images/instant_transaction.png" alt="" />
                                </div>
                                <h5 className="heading"><a>Instant Transaction</a></h5>

                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-3 col-md-6 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <div className="image">
                                    <img src="assets/images/easy_and_convenient_system.png" alt="" />
                                </div>
                                <h5 className="heading"><a>Easy & Convenient System</a></h5>

                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-2 col-md-6 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <div className="image">
                                    <img src="assets/images/smart_web.png" alt="" />
                                </div>
                                <h5 className="heading"><a>Smart <br /> Web</a></h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
