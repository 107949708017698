import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Ecosystem from './Components/Ecosystem/Ecosystem';
import CommingSoon from './Components/CommingSoon';

function App() {
  return (
   <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/ecosystem" element={<Ecosystem/>} />
        <Route path="/comming-soon" element={<CommingSoon/>} />
      </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
