import React from 'react'

export default function Footer() {
    return (
        <>
            <footer id="footer" style={{ backgroundImage: `url('./assets/images/footer_frame_01.svg')`, backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%", backgroundSize: "cover" }}>
                <div className="themesflat-container">
                    <div className="row pb-4">
                        <div className="col-12">
                            <div className="footer-content flex flex-grow justify-content-center">
                                <div className="widget-socia">
                                    <ul className="flex justify-content-between">
                                        {/* <li><a href="#" className="icon-facebook" /></li> */}
                                        <li className='px-2'><a href="https://x.com/SDCProtocol" target='_blank' > <img src="./assets/images/twt.png" alt="TWITTER" /></a></li>
                                        <li className='px-2'><a href="https://t.me/SDCEcosystem" target='_blank'  > <img src="./assets/images/telegram.png" alt="TELEGRAM" /></a></li>
                                        <li className='px-2'><a href="https://www.instagram.com/sdc_ecosystem/" target='_blank' ><img src="./assets/images/insta.png" alt="INSTAGRAM" /></a></li>
                                        {/* <li><a href="#" className="icon-youtube" /></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom justify-content-center">
                        <p>All Rights Reserved © 2024 SDC</p>
                    </div>
                </div>
            </footer>
        </>
    )
}