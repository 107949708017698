import React from 'react'
import Header from './Comman/Header'
import Footer from './Comman/Footer'

export default function CommingSoon() {
    return (
        <>
            <Header />
            <div id='page' className='pt-40' style={{ backgroundImage: `url('assets/images/coming_soon.gif')`, opacity: "0.8", backgroundPosition: "50% 50%", backgroundSize: "cover", height: "80vh" }}>

            </div>
            <Footer />
        </>
    )
}
