import React from 'react'
import { Link } from 'react-router-dom'

export default function Transparency() {
    return (
        <>
            <div id='Tokenomics' className="tf-section create-sell">
                <div className="themesflat-container">
                    <div className="row pl-2-5 align-items-center justify-content-center">
                        <div className="col-md-12">
                            <div className="heading-sectio text-center pt-5">
                                <h2 className="tf-title pb-5">Transparency</h2>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <img src="./assets/images/tokenomic.png" alt="" />
                        </div> */}
                        <div className="col-lg-8">
                            <div className="side-bar">
                                <div className="widget widget-categories">
                                    {/* <h5 className="title-widget">Notice! Be aware of fraud contracts & Please verify the below Token contract address carefully before making any Transaction.</h5> */}
                                    <ul>
                                        <li>
                                            <div className="cate-item">NGO :</div>
                                            <div className="number"><Link to="https://bscscan.com/address/0xEC0A6F58F832aa997E2A60Dd7A03092D4E6277b5" target='_blank'>0xEC0A6F58F832aa997E2A60Dd7A03092D4E6277b5</Link></div>
                                        </li>
                                        <li>
                                            <div className="cate-item">Developer :</div>
                                            <div className="number"><Link to="https://bscscan.com/address/0x1b3F36C9d2F0Eee5A4228e95B34dbF02b64142e9" target='_blank'>0x1b3F36C9d2F0Eee5A4228e95B34dbF02b64142e9</Link></div>
                                        </li>
                                        <li>
                                            <div className="cate-item">Marketing :</div>
                                            <div className="number"><Link to="https://bscscan.com/address/0xa875F1361FE4343f8c3eFDC4aFDF39D3C3Ec4E72" target='_blank'>0xa875F1361FE4343f8c3eFDC4aFDF39D3C3Ec4E72</Link></div>
                                        </li>
                                        <li>
                                            <div className="cate-item">Founder :</div>
                                            <div className="number"><Link to="https://bscscan.com/address/0x9aef30404017b2a53a0077b4f0a680265898dcf2" target='_blank'>0x9aef30404017b2a53a0077b4f0a680265898dcf2</Link></div>
                                        </li>
                                        <li>
                                            <div className="cate-item">Parabanking :</div>
                                            <div className="number"><Link to="https://bscscan.com/address/0xb539f98bc677b68316d082996c024c2178cdc720" target='_blank'>0xb539f98bc677b68316d082996c024c2178cdc720</Link></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
