import React from 'react'
import { Link } from 'react-router-dom'

export default function Eccosystem() {
    return (
        <>
            <div className="tf-section action">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="action__body">
                                <div className="tf-tsparticles">
                                    <div id="tsparticles1" data-color="#161616" data-line="#000" />
                                </div>
                                <h2>SDC Ecosystem</h2>
                                <div className="flat-button flex justify-content-between">
                                    <a href="https://smartdefiworld.com/" target='_blank' className="tf-button style-2 h50 w190 mr-10">Smartdefi<i className="icon-arrow-up-right2" /></a>
                                    <a href="/comming-soon" className="tf-button style-2 h50 w230">S-Mart<i className="icon-arrow-up-right2" /></a>
                                    <a href="/comming-soon" className="tf-button style-2 h50 w190 mr-10">Smart wallet<i className="icon-arrow-up-right2" /></a>
                                    <a href="/comming-soon" className="tf-button style-2 h50 w230">Smart Chain<i className="icon-arrow-up-right2" /></a>
                                </div>
                                <div className="flat-button flex justify-content-center my-4">
                                    <Link to="/ecosystem" className="tf-button style-2 h50 w190 mr-10">Ecosystem<i className="icon-arrow-up-right2" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
