import React from 'react'
import { Link } from 'react-router-dom'

export default function StakingWork() {
    return (
        <>
            <div className="tf-section action">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="action__body m-0">
                                <div className="tf-tsparticles">
                                    <div id="tsparticles1" data-color="#161616" data-line="#000"><canvas className="tsparticles-canvas-el" width={1110} height={268} style={{ width: '100%', height: '100%' }} /></div>
                                </div>
                                <h2 className="text-center capitalize mx-auto mw-667">Join the world’s largest Smart community &amp; start collecting Rewards</h2>
                                <div className="flat-button flex justify-center">
                                    <Link to="/ecosystem" className="tf-button style-2 h50 w190">Join Ecosystem<i className="icon-arrow-up-right2" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
