import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
    const [show, setShow] = useState(false);
    return (
        <>
            <header id="header_main" className="header_1" style={{ backgroundImage: `url('./assets/images/header_frame_01.svg')`, backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%", backgroundSize: "cover", height: "120px" }}>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12 text">
                            <div id="site-header-inner">
                                <div className="wrap-box flex">
                                    <div id="site-logo">
                                        <NavLink to="/">
                                        <img id="logo_header" src="assets/images/logo/favicon.png" className="animated-image pt-3" />
                                        </NavLink>
                                        <div id="site-logo-inner">
                                            <Link to="/" rel="home" className="main-logo">
                                                <div className='animated-image-container'>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="mobile-button" onClick={() => setShow(true)}>
                                        <span />
                                    </div>
                                    {/* /.mobile-button */}
                                    <nav id="main-nav" className="main-nav">
                                        <ul id="menu-primary-menu" className="menu">
                                            <li className="menu-item">
                                                <a href="#Aboutus">About us</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#Tokenomics">Tokenomics</a>
                                            </li>
                                            <li className="#menu-item">
                                                <a href="#Roadmap">Roadmap</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#Faqs">Faqs</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    {/* /#main-nav */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="canvas-nav-wrap">
                    <div className="overlay-canvas-nav" />
                    <div className="inner-canvas-nav">
                        <div className="side-bar">
                            <Link to="/" rel="home" className="">
                                <img id="" src="assets/images/logo/favicon.png" />
                            </Link>
                            <div className="canvas-nav-close">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" xmlSpace="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
                            </div>
                            <div className="widget-search mt-30">
                                <form action="#" method="get" role="search" className="search-form relative">
                                    <input type="search" id="search" className="search-field style-1" placeholder="Search..." defaultValue name="s" title="Search for" required />
                                    <button className="search search-submit" type="submit" title="Search">
                                        <i className="icon-search" />
                                    </button>
                                </form>
                            </div>
                          
                            <div className="widget widget-menu style-4">
                                <h5 className="title-widget">Company</h5>
                                <ul>
                                    <li><a href="#">Help center</a></li>
                                    <li><a href="#">Platform status</a></li>
                                </ul>
                            </div>
                            <div className="widget">
                                <h5 className="title-widget">Join the community</h5>
                                <div className="widget-social">
                                    <ul className="flex">
                                        <li><a href="#" className="icon-facebook" /></li>
                                        <li><a href="#" className="icon-twitter" /></li>
                                        <li><a href="#" className="icon-vt" /></li>
                                        <li><a href="#" className="icon-tiktok" /></li>
                                        <li><a href="#" className="icon-youtube" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={show ? "mobile-nav-wrap active" : "mobile-nav-wrap"}>
                    <div className="overlay-mobile-nav" />
                    <div className="inner-mobile-nav">
                        <Link to="/" rel="home" className="main-logo">
                            <img id="mobile-logo_header" src="assets/images/logo/favicon.png" width="70" />
                        </Link>
                        <div className="mobile-nav-close" onClick={() => setShow(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" xmlSpace="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
                        </div>
                        <nav id="mobile-main-nav" className="mobile-main-nav">
                            <ul id="menu-mobile-menu" className="menu">
                                <li className="menu-item">
                                    <a href="#Aboutus" onClick={() => setShow(false)}>About us</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#Tokenomics" onClick={() => setShow(false)}>Tokenomics</a>
                                </li>
                                <li className="#menu-item">
                                    <a href="#Roadmap" onClick={() => setShow(false)}>Roadmap</a>
                                </li>
                                <li className="menu-item">
                                    <a href="#Faqs" onClick={() => setShow(false)}>Faqs</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}
