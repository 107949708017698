import React from 'react'

export default function Hero() {
    return (
        <>
            <div className="flat-pages-title-home2 relative">
                <div className="themesflat-container w-full">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="content">
                                <h1 data-wow-delay="0s" className="wo">Redefining Digital Finance Sector
                                </h1>
                                <p data-wow-delay="0.1s" className="wo">Smart Digital Connect (SDC), a community-driven ecosystem, revolutionizes digital finance by leveraging blockchain technology and enabling peer-to-peer decentralized transactions on a global scale, while ensuring the security and transparency of transactions. </p>
                                <div data-wow-delay="0.2s" className="wo flat-button flex">
                                    <a href="https://smartbull.io/trade/SDC_USDT" target='_blank' className="tf-button style-1 h50 w190 mr-30 btn-alt">Buy SDC<i className="icon-arrow-up-right2" /></a>
                                    <a href="./assets/sdc-whitepaper.pdf" target='_blank' className="tf-button style-1 h50 w190 active btn-alt">Whitepaper<i className="icon-arrow-up-right2" /></a>
                                </div>
                                {/* <video muted
                                    autoPlay
                                    loop id="myVideo" className='video'><source src="./assets/images/hero_banner_smallblockchain_file_new.mp4" type="video/mp4" /></video> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <video muted
                                autoPlay
                                loop id="myVideo" className='video'><source src="./assets/images/hero_banner_blockchain.mp4" type="video/mp4" /></video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
