import React from 'react'

export default function Aboutus() {
    return (
        <>
            <div id='Aboutus' className="tf-section create-sell">
                <div className="themesflat-container">
                    <div className="row pl-2-5">
                        <div className="col-md-12">
                            <div className="heading-sectio text-center">
                                <h2 className="tf-title pb-30">What is SDC?</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <h1 data-wow-delay="0s" className="wo pb-30">About The SDC</h1>
                                <p data-wow-delay="0.1s" className="wo">Smart Digital Connect (SDC) is a revolutionary ecosystem in the digital finance sector, designed to address the challenges of decentralized finance by leveraging advanced blockchain technology. SDC operates on a community-driven governance model, ensuring secure and transparent transactions while fostering inclusivity and collaboration. By focusing on long-term sustainability and societal advancements, SDC aims to build a decentralized finance economy that promises a sustainable future through products and services like SDC Token, DeFi, Staking, Exchange, Gamify, NFT-Fi, and other Blockchain applications.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <img src="assets/images/step_by_step_nft.gif" alt="" />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div data-wow-delay="0s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step1 relative">
                                <h5 className="heading">Community-Driven Governance</h5>
                                <p>Fosters inclusivity and collaboration among community members.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.1s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step2 relative">
                                <h5 className="heading">Advanced Blockchain Technology</h5>
                                <p>Uses BNB Blockchain for secure, transparent, and immutable transactions.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.2s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step3 relative">
                                <h5 className="heading">Peer-to-Peer Transactions</h5>
                                <p>Enables global, decentralized financial interactions.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <h5 className="heading">Sustainability</h5>
                                <p>Long-term focus with a 40-year vesting roadmap.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <h5 className="heading">Diverse Ecosystem</h5>
                                <p>Includes SDC Token, DeFi, staking, exchange platform, gamify, NFT-Fi, and blockchain applications.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <h5 className="heading">Transparency</h5>
                                <p>Fully transparent ecosystem to prevent fund custody issues and asset losses.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.3s" className="col-lg-4 col-md-6 my-2 animated" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                            <div className="tf-box-icon style-1 step4 relative">
                                <h5 className="heading">Societal Advancement</h5>
                                <p>Focuses on equal wealth distribution, distributed partnerships, and community initiatives.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
